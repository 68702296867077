
.home {
  color: #fff;
}

.textFields {
  margin: 10px 0 !important;
}

.actionButton {
  display: flex;
  justify-content: flex-end;
}

.actionButton button {
  border-radius: 20px !important;
  margin: 5px;
}

.error {
  color: red !important;
  margin: 0 !important;
  margin-top: -8px !important;
}

.group2Textfield {
  display: flex;
}

.group2Textfield .textFields {
  margin: 10px 5px !important;
}

.autoCompleteField {
  width: 100%;
  margin: 10px 5px !important;
}

.amountBody {
  width: 100%;
  margin: 0 10px 0 0 !important;
}

.amountBody input {
  text-align: right !important;
}

.amountInWord {
  text-transform: capitalize;
}

.expenseTypeBody {
  width: 100%;
  margin: 0 10px 0 0;
}

.headerContent {
  display: flex;
  justify-content: space-between;
  font-size: 19px;
  align-items: center;
  background: cadetblue;
  color: aliceblue;
  font-weight: 600;
  padding: 0 10px;
  margin-top: 10px;
}

.showTitleHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.detailsBody {
  display: flex;
  font-size: 16px;
}

.leftShowDetails {
  width: 50%;
}

.rightShowDetails {
  width: 50%;
}

.showTitleHeader h2 {
  margin: 0;
}

.detailList {
  display: flex;
  margin: 5px;
}

.tabListBody > div {
  padding-top: 0 !important;
}

.detailList div:first-child {
  min-width: 300px;
}

.tabLabel {
  font-weight: bold;
}

.journalDetailsBody .detailList div:first-child {
  min-width: 200px !important;
}

.checkDetailsHeader {
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
}

.checkDetailsHeader h3 {
  margin: 0;
}

.groupButtonTab {
  display: flex;
  align-items: flex-end;
}

.formBody {
  height: 200px !important;
  border: dashed !important;
  display: grid;
  margin-bottom: 20px;
}

form .input {
  position: relative;
  opacity: 0;
  z-index: 99;
  height: 200px;
}

form .filelabel {
  text-align: center;
  margin-top: -109px;
}

.fileError {
  color: red;
}

.actionAttactmentButton {
  display: flex;
  justify-content: flex-end;
}

.actionAttactmentButton button {
  border-radius: 20px !important;
  margin-top: 45px;
}

.valueDetails {
  display: flex;
  justify-content: space-between;
}

.accountWidth {
  width: 300px;
}

.small {
  width: 150px;
}

.medium {
  width: 210px;
}

.removeStyle {
  text-decoration: none;
  color: black;
}

.actionButtonHoverable {
  position: absolute;
  min-width: 65px;
  right: 40px !important;
  margin-top: -60px !important;
  border-bottom: none !important;
  opacity: 0;
  z-index: 10;
}

.actionButtonHoverable td {
  border-bottom: none !important;
}

.actionButtonHoverable:hover {
  opacity: 1;
}

.tableBody tr:hover + .actionButtonHoverable {
  opacity: 1;
  transition: opacity 1s;
}

.switch {
  margin-left: 20px !important;
}

.errorBlock {
  margin-top: -20px !important;
  display: flex
}

.errorField {
  width: 100%;
  font-style: italic;
  margin: 8px !important;
  color: red !important;
  text-align: left;
}

.tableBodyHeight {
  margin: 0 10px;
}

.noDisplay {
  display: none !important;
}

.labelDisplay h2 {
  margin: 0 !important;
}